import type { GridProps, HTMLChakraProps } from '@chakra-ui/react';
import { Box, Grid, Flex, Text, Link, VStack, Skeleton, useColorModeValue } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import Image from 'next/image';
import React from 'react';

import type { CustomLinksGroup } from 'types/footerLinks';

import config from 'configs/app';
import type { ResourceError } from 'lib/api/resources';
import useApiQuery from 'lib/api/useApiQuery';
import useFetch from 'lib/hooks/useFetch';
// import useIssueUrl from 'lib/hooks/useIssueUrl';
import { copy } from 'lib/html-entities';
import { CONTENT_MAX_WIDTH } from 'ui/shared/layout/utils';
import NetworkAddToWallet from 'ui/shared/NetworkAddToWallet';

import FooterLinkItem from './FooterLinkItem';
import IntTxsIndexingStatus from './IntTxsIndexingStatus';
import PopupCookie from './PopupCookie';
import getApiVersionUrl from './utils/getApiVersionUrl';

const MAX_LINKS_COLUMNS = 4;

const FRONT_VERSION_URL = '#';
const FRONT_COMMIT_URL = '#';

const Footer = () => {
  const { data: backendVersionData } = useApiQuery('config_backend_version', {
    queryOptions: {
      staleTime: Infinity,
    },
  });
  const apiVersionUrl = getApiVersionUrl(backendVersionData?.backend_version);
  // const issueUrl = useIssueUrl(backendVersionData?.backend_version);
  const logoColor = useColorModeValue('blue.600', 'white');

  const BLOCKSCOUT_LINKS = [
    {
      // icon: 'edit' as const,
      // iconSize: '16px',
      text: 'Privacy Policy',
      url: 'https://oneworldchain.org/privacypolicy',
    },
    {
      // icon: "social/canny" as const,
      // iconSize: "20px",
      text: 'Advertise',
      url: 'mailto:owc@oneworldchain.org',
    },
    {
      // icon: "social/git" as const,
      // iconSize: "18px",
      text: 'Terms Of Service',
      url: 'https://oneworldchain.org/tos',
    },
    {
      // icon: "social/twitter" as const,
      // iconSize: "18px",
      text: 'Careers',
      url: 'mailto:contact@oneworldchain.org',
    },
    {
      // icon: "social/discord" as const,
      // iconSize: "24px",
      text: 'RPC - Chain ID',
      url: 'https://chainlist.org/chain/309075',
    },
    {
      // icon: "brands/blockscout" as const,
      // iconSize: "18px",
      text: 'Testnet Faucet',
      url: 'https://faucet.oneworldchain.org/',
    },
    {
      // icon: "donate" as const,
      // iconSize: "20px",
      text: 'OWCT Bridge',
      url: 'https://bridge.oneworldchain.org/',
    },
    {
      // icon: "donate" as const,
      // iconSize: "20px",
      text: 'Brand Assets',
      url: 'https://oneworldchain.org/brand-asset',
    },
    {
      // icon: "donate" as const,
      // iconSize: "20px",
      text: 'Contacts Us',
      url: 'mailto:owc@oneworldchain.org',
    },
  ];

  const frontendLink = (() => {
    if (config.UI.footer.frontendVersion) {
      return (
        <Link href={ FRONT_VERSION_URL } target="_blank">
          { config.UI.footer.frontendVersion }
        </Link>
      );
    }

    if (config.UI.footer.frontendCommit) {
      return (
        <Link href={ FRONT_COMMIT_URL } target="_blank">
          { config.UI.footer.frontendCommit }
        </Link>
      );
    }

    return null;
  })();

  const fetch = useFetch();

  const { isPlaceholderData, data: linksData } = useQuery<unknown, ResourceError<unknown>, Array<CustomLinksGroup>>({
    queryKey: [ 'footer-links' ],
    queryFn: async() => fetch(config.UI.footer.links || '', undefined, { resource: 'footer-links' }),
    enabled: Boolean(config.UI.footer.links),
    staleTime: Infinity,
    placeholderData: [],
  });

  const colNum = isPlaceholderData ? 1 : Math.min(linksData?.length || Infinity, MAX_LINKS_COLUMNS) + 1;

  const renderNetworkInfo = React.useCallback((gridArea?: GridProps['gridArea']) => {
    return (
      <Flex gridArea={gridArea} flexWrap="wrap" columnGap={8} rowGap={6} mb={{ base: 5, lg: 10 }} _empty={{ display: 'none' }}>
        {!config.UI.indexingAlert.intTxs.isHidden && <IntTxsIndexingStatus/>}
        <NetworkAddToWallet/>
        <div className="flex gap-[15px]">
          <Link href="https://x.com/oneworldchain" target="_blank">
            <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="15" cy="15.5" r="15" fill="#025BDB"/>
              <path
                d="M6.46994 6.92773L13.0893 16.386L6.42773 24.0706H7.92606L13.7576 17.3405L18.4706 24.0706H23.5706L16.5784 14.0825L22.7792 6.92773H21.2809L15.9101 13.1243L11.5699 6.92773H6.46994ZM8.67523 8.10767H11.0177L21.3653 22.8907H19.0228L8.67523 8.10767Z"
                fill="white"
              />
            </svg>
          </Link>
          <Link href="https://t.me/oneworldchain" target="_blank">
            <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="15" cy="15.5" r="15" fill="#025BDB"/>
              <path
                d="M4.67037 15.1961L9.16449 16.897L10.8337 22.523C10.9621 22.9155 11.3473 23.0463 11.6042 22.7847L14.0438 20.6913C14.3006 20.4296 14.6858 20.4296 14.9426 20.6913L19.4368 23.9622C19.6936 24.2239 20.2072 23.9622 20.2072 23.7005L23.5457 7.60758C23.6741 7.21507 23.2889 6.82256 22.9037 6.9534L4.67037 14.1494C4.15675 14.2803 4.15675 14.9345 4.67037 15.1961ZM10.5769 15.9811L19.3084 10.486C19.4368 10.3552 19.5652 10.6168 19.4368 10.7477L12.2462 17.5512C11.9894 17.8129 11.861 18.0745 11.7326 18.4671L11.4758 20.2988C11.4758 20.5604 11.0905 20.5604 11.0905 20.2988L10.1917 16.897C10.0633 16.6353 10.1917 16.112 10.5769 15.9811Z"
                fill="white"
              />
            </svg>
          </Link>
        </div>
      </Flex>
    );
  }, []);

  const renderProjectInfo = React.useCallback(
    (gridArea?: GridProps['gridArea']) => {
      return (
        <Box gridArea={ gridArea }>
          <div className="flex gap-4 items-center mb-5">
            <Image src="/static/owct_logo.png" alt="logo" width={ 48 } height={ 48 }/>
            <Text className="font-bold text-2xl">Powered by One World Chain</Text>
          </div>
          <Link href="https://oneworldchain.org/" target="_blank" className="text-[15px] !text-[#025BDB]">
            oneworldchain.org
          </Link>
          <Text mt={ 3 } fontSize="xs">
            One World Chain Blockchain is a Block Explorer and Analytics platform for One World Chain Ecosystem a decentralized smart contract blockchain.
          </Text>
          <Box mt={ 6 } alignItems="start" fontSize="xs" lineHeight={ 5 }>
            <Text>
              One World Chain © { new Date().getFullYear() } | Built by <b>One World Chain Team</b>
            </Text>
          </Box>
        </Box>
      );
    },
    [ apiVersionUrl, backendVersionData?.backend_version, frontendLink, logoColor ],
  );

  const containerProps: HTMLChakraProps<'div'> = {
    as: 'footer',
    borderTopWidth: '1px',
    borderTopColor: 'solid',
  };

  const contentProps: GridProps = {
    px: { base: 4, lg: config.UI.navigation.layout === 'horizontal' ? 6 : 12, '2xl': 6 },
    py: { base: 4, lg: 8 },
    gridTemplateColumns: { base: '1fr', lg: 'minmax(auto, 470px) 1fr' },
    columnGap: { lg: '32px', xl: '100px' },
    maxW: `${ CONTENT_MAX_WIDTH }px`,
    m: '0 auto',
  };

  if (config.UI.footer.links) {
    return (
      <Box { ...containerProps }>
        <Grid { ...contentProps }>
          <div>
            { renderNetworkInfo() }
            { renderProjectInfo() }
          </div>

          <Grid
            gap={{ base: 6, lg: colNum === MAX_LINKS_COLUMNS + 1 ? 2 : 8, xl: 12 }}
            gridTemplateColumns={{
              base: 'repeat(auto-fill, 160px)',
              lg: `repeat(${ colNum }, 135px)`,
              xl: `repeat(${ colNum }, 160px)`,
            }}
            justifyContent={{ lg: 'flex-end' }}
            mt={{ base: 8, lg: 0 }}
          >
            { [ { title: 'OWCT', links: BLOCKSCOUT_LINKS }, ...(linksData || []) ].slice(0, colNum).map((linkGroup) => (
              <Box key={ linkGroup.title }>
                <Skeleton fontWeight={ 500 } mb={ 3 } display="inline-block" isLoaded={ !isPlaceholderData }>
                  { linkGroup.title }
                </Skeleton>
                <VStack spacing={ 1 } alignItems="start">
                  { linkGroup.links.map((link) => (
                    <FooterLinkItem { ...link } key={ link.text } isLoading={ isPlaceholderData }/>
                  )) }
                </VStack>
              </Box>
            )) }
          </Grid>
        </Grid>
        <PopupCookie/>
      </Box>
    );
  }

  return (
    <Box { ...containerProps }>
      <Grid
        { ...contentProps }
        gridTemplateAreas={{
          lg: `
          "network links-top"
          "info links-bottom"
        `,
        }}
      >
        { renderNetworkInfo({ lg: 'network' }) }
        { renderProjectInfo({ lg: 'info' }) }

        <Grid
          gridArea={{ lg: 'links-bottom' }}
          rowGap="25px"
          columnGap={ 1 }
          gridTemplateColumns={{
            base: 'repeat(auto-fill, 160px)',
            lg: 'repeat(3, 160px)',
            xl: 'repeat(3, 300px)',
          }}
          gridTemplateRows={{
            base: 'auto',
            lg: 'repeat(3, auto)',
            xl: 'repeat(3, auto)',
          }}
          gridAutoFlow={{ base: 'row', lg: 'column' }}
          alignContent="start"
          justifyContent={{ lg: 'flex-end' }}
          mt={{ base: 8, lg: 0 }}
        >
          { BLOCKSCOUT_LINKS.map((link) => (
            <FooterLinkItem { ...link } key={ link.text }/>
          )) }
        </Grid>
      </Grid>
      <PopupCookie/>
    </Box>
  );
};

export default React.memo(Footer);
